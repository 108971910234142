.results-category-wrap{
margin: 0 auto;
max-width: 1024px;
padding-top: 70px;
font-family: "Overpass";
color: black;
}

.results-category-title{
font-size: 20px;
color: black;
font-weight: 500;
text-align: center;
padding-top: 20px;
}

.results-category-text{
font-size: 18px;
color: black;
font-weight: 100;
padding-top: 10px;
}

.results-category-wrap1{
margin-top: 20px;
width: 100%;
display: flex;
flex-direction: row;
justify-content: flex-start;
}

.results-category-left-wrap{
width: 33%;
}

.results-category-right-wrap{
width: 67%;
}

.results-category-create-pdf{
    margin-top: 15px;
    font-size: 16px;
    font-weight: 500;
    color: black;
    text-decoration: underline;
    cursor: pointer;
    outline: none;
}





@media screen and (max-width: 600px) {


    .results-category-wrap{
    width: 92%;
    }

    

}