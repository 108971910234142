@font-face {
	font-family: 'Overpass';
	font-style: normal;
	font-weight: 100;
	src: local('Overpass'), 
	url(./fonts/Overpass-Thin.ttf) format('truetype');
}
@font-face {
	font-family: 'Overpass';
	font-style: normal;
	font-weight: 300;
	src: local('Overpass'), 
	url(./fonts/Overpass-Light.ttf) format('truetype');
}
@font-face {
	font-family: 'Overpass';
	font-style: normal;
	font-weight: 400;
	src: local('Overpass'), 
	url(./fonts/Overpass-Regular.ttf) format('truetype');
}
@font-face {
	font-family: 'Overpass';
	font-style: normal;
	font-weight: 500;
	src: local('Overpass'), 
	url(./fonts/Overpass-Medium.ttf) format('truetype');
}
@font-face {
	font-family: 'Overpass';
	font-style: normal;
	font-weight: 700;
	src: local('Overpass'), 
	url(./fonts/Overpass-Bold.ttf) format('truetype');
}