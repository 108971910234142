.results-vehicle-wrap{
    margin: 0 auto;
    max-width: 1024px;
    padding-top: 70px;
    font-family: "Overpass";
    color: black;
    padding-bottom: 20px;
}

.results-vehicle-title{
    font-size: 20px;
    color: black;
    font-weight: 500;
    text-align: center;
    padding-top: 20px;
}

.results-vehicle-name-wrap{
    font-size: 16px;
    color: black;
    font-weight: 500;
    /* text-align: center; */
    padding-top: 10px;
}
.results-vehicle-name-main{
    font-size: 18px;
color: #0072ce
}
.results-vehicle-name-secondary{
font-size: 14px;
color: black;
font-weight: 300;
}

.results-vehicle-info-wrap{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.results-vehicle-info-label{
    width: 120px;
    font-size: 16px;
    color: rgb(126, 126, 126);
    font-weight: 100;
}
.results-vehicle-info-text{
    width: 400px;
    font-size: 16px;
    color: black;
    font-weight: 300;  
}







@media screen and (max-width: 600px) {


    .results-vehicle-wrap{
    width: 92%;
    }

    

}