
html, body, ul, li, p, h1, h3, h4, h5 {
margin: 0;
font-family: inherit;
list-style: none;
text-decoration: none
}


.main-body-fixed-header{
padding-top: 60px;
}

.homepage-divider{
  margin: 0 auto;
  max-width: 1024px;
  height: 5px;
  border-bottom: 1px solid #cccccc;
}

.banner-sobre-wrap{
  margin: 0 auto;
  max-width: 1024px;
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: "Overpass";
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.banner-sobre-img{
width: 66.66667%;
}
.banner-sobre-img img{
  width: 100%;
height: auto;
}
.banner-sobre-text-wrap{
  width: 33.33333%;
  margin-left: 16px;
  margin-right: 16px;
}
.banner-sobre-text-title{
  font-size: 20px;
  font-weight: 700;
}
.banner-sobre-text-main{
  padding-top: 20px;
  font-size: 14px;
  font-weight: 300;
}








@media screen and (max-width: 600px) {


.banner-sobre-wrap{
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}


.banner-sobre-img{
width: 80%;
}
.banner-sobre-img img{
height: auto;
}
.banner-sobre-text-wrap{
width: 80%;
margin-left: 0px;
margin-right: 0px;
margin-top: 10px;
}
.banner-sobre-text-title{
font-size: 20px;
font-weight: 700;
}
.banner-sobre-text-main{
padding-top: 20px;
font-size: 14px;
font-weight: 300;
}

.banner-sobre-text-title{
font-size: 20px;
font-weight: 700;
}
.banner-sobre-text-main{
padding-top: 20px;
font-size: 14px;
font-weight: 300;
}

}