.results-make-wrap{
margin: 0 auto;
max-width: 1024px;
min-height: 900px;
padding-top: 70px;
font-family: "Overpass";
color: black;
}

.results-make-title{
font-size: 20px;
color: black;
font-weight: 500;
text-align: left;
padding-top: 20px;
}

.results-make-text{
font-size: 20px;
color: #0072ce;
font-weight: 700;
text-align: center;
}

.results-make-wrap1{
width: 100%;
display: flex;
flex-direction: row;
justify-content: flex-start;
}

.results-make-left-wrap{
margin-top: 20px;
width: 25%;
}
.results-make-right-wrap{
margin-top: 20px;
width: 75%;
}

.results-make-filter-title{
width: 70%;
font-size: 14px;
/* margin-bottom: 10px; */
color: #0072ce
}

.results-make-filter-model-wrap{
    width: 70%;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    max-height: 500px;
    overflow-y: auto;
}
.results-make-filter-model{
    font-weight: 100;
    font-size: 14px;
    padding: 5px;
    cursor: pointer;
    outline: none;
}








@media screen and (max-width: 600px) {


.results-make-wrap{
width: 92%;
}

.results-make-wrap1{
flex-direction: column;
width: 100%;
}


.results-make-left-wrap{
width: 100%;
}
.results-make-right-wrap{
width: 100%;
}

.results-make-filter-model-wrap{
width: 100%;
max-height: 200px;
}


}