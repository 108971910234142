html, body, ul, li, p, h1, h3, h4, h5 {
padding: 0;
margin: 0;
font-family: inherit;
list-style: none;
text-decoration: none;
}


.header-bkgd {
background-color: #363636;
position: fixed;
width: 100%;
min-height: 60px;
z-index: 1;
}

.header-wrap {
margin: 0 auto;
min-height: 55px;
max-width: 1024px;
display: flex;
align-items: center;
justify-content: flex-start;
z-index: 1;
}
.header-logo-pc{
margin-left: 0px;
padding-top: 10px;
cursor: pointer;
outline: none;
}

.header-title-wrap{
    font-family: "Overpass";
    font-size: 16px;
    font-weight: 500;
    color: white;
    margin-left: 30px;
    padding-left: 30px;
    border-left: 1px solid white;
}
.header-title1{

}
.header-title2{

}




@media screen and (max-width: 600px) {

.header-wrap {
margin-left: 10px;
}
.header-title-wrap{
margin-left: 10px;
padding-left: 10px;
}

}