
.result-make-cards-wrap {
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
}

.result-category-cards-naoxistem{

}

.navigation{
margin-top: 20px;
margin-bottom:0px;
font-family: "Overpass";
font-weight: 100;
font-size: 14px;
display: flex;
flex-direction: row;
justify-content: flex-start;
}
.navigation-bottom{
margin-top: 0px;
margin-bottom:20px;
font-family: "Overpass";
font-weight: 100;
font-size: 14px;
display: flex;
flex-direction: row;
justify-content: flex-start;
}

.navigation-total-parts{
}

.navigation-arrow{
cursor: pointer;
margin-left: 5px;
margin-right: 5px;
}

.navigation-arrow:hover{
filter: invert(100%) sepia(100%) saturate(48%) hue-rotate(271deg) brightness(93%) contrast(85%);
}

.navigation-page{
}

.result-make-cards {
display: flex;
flex-direction: column;
flex-wrap: wrap;
}

.result-make-subtitle{
    font-size: 14px;
    color: #0072ce
}







@media screen and (max-width: 600px) {

.result-make-cards {
width: 100%;
}




}