.category-card-principal{
width: 20%;
min-width: 230px;
height: 300px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
border: 1px solid #cccccc;
padding-top: 10px;
padding-bottom: 10px;
margin-bottom: 30px;
margin-right: 20px;
}

.category-card-foto{
border: 1px solid #e6e6e6;
width: 90%;
}
.category-card-foto img{
width: 100%;
}
.category-card-text{
min-height: 120px;
width: 90%;
display: flex;
flex-direction: column;
justify-content: space-between;
margin-top: 10px;
}

.category-card-text-pn-name-wrap{
display: flex;
flex-direction: column;
justify-content: flex-start;
font-family: "Overpass";
color: black;
font-size: 16px;
font-weight: 700;
}
.category-card-text-pn{
color: #0072ce
}
.category-card-text-name{
font-weight: 300;
}
.category-card-text-name-addit{
font-size: 14px;
font-weight: 100;
}

.category-card-botao-wrap{
width: 100px;
/* margin-top: 10px; */
}
.category-card-botao-comprar-show{
font-family: "Overpass";
font-size: 12px;
background-color: #4caf50;
border: none;
color: white;
padding: 4px 20px;
text-align: center;
text-decoration: none;
display: inline-block;
margin: 4px 0px;
cursor: pointer;
outline: none
}
.category-card-botao-comprar-show-notavailable{
font-family: "Overpass";
font-size: 12px;
background-color: #cccccc;
border: none;
color: black;
padding: 4px 20px;
text-align: center;
text-decoration: none;
display: inline-block;
margin: 4px 0px;
}


.category-card-ver-aplic{
padding-top: 0px;
padding-bottom: 0px;
font-size: 12px;
color: rgb(148, 148, 148);
font-weight: 500;
text-decoration: underline;
cursor: pointer;
outline: none;
}

.onloading-wrap{
width: 50px;
}
.onloading-wrap img{
width: 50px;
}










@media screen and (max-width: 600px) {



    .category-card-principal{
        /* width: 30%; */
        min-width: 170px;
        height: 300px;
        }


        .category-card-text-pn-name-wrap{
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 20px;
            }

            .category-card-text-name-addit{
                margin-bottom: 10px;
                }
}