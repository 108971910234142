.make-card-principal{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 14px;
    background-color: #f1f2f1;
    padding-left: 7px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 7px;
    margin-bottom: 7px;
    cursor: pointer;
    outline: none;
}
.make-card-text-model{
font-weight: 700;
}
.make-card-text-version{

}
.make-card-text-engtrans{
font-weight: 300;
}






@media screen and (max-width: 600px) {


    .make-card-principal{
        width: 100%;
        flex-direction: column;
    }
    
    
    }