

.result-category-cards-wrap {
    margin: 0 auto;
    width: 100%;
    /* min-height: 700px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 35px;
    }

.result-category-cards-naoxistem{

}

.navigation-categories{
/* margin-top: 20px; */
margin-bottom:0px;
font-family: "Overpass";
font-weight: 100;
font-size: 14px;
display: flex;
flex-direction: row;
justify-content: flex-start;
}

.navigation-total-parts{
}

.navigation-arrow{
cursor: pointer;
margin-left: 5px;
margin-right: 5px;
}

.navigation-arrow:hover{
filter: invert(100%) sepia(100%) saturate(48%) hue-rotate(271deg) brightness(93%) contrast(85%);
}

.navigation-page{
}

.result-category-cards {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    flex-wrap: wrap;
}

/* .result-category-cards>* {
flex: 0 0 30%;
} */