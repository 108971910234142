html, body, ul, li, p, h1, h3, h4, h5 {
margin: 0;
font-family: inherit;
list-style: none;
text-decoration: none;
}

*,
*::before,
*::after {
box-sizing: border-box;
}

.vehicle-selector-bkgd{
width: 100%;
background-color: black;
height: 265px;
}
.vehicle-selector-main-wrap{
margin: 0 auto;
max-width: 1024px;
}

.vehicle-selector-title{
padding-top: 20px;
padding-bottom: 10px;
font-family: "Overpass";
font-size: 20px;
font-weight: 700;
color: #0072ce

}
.vehicle-selector-text{
padding-bottom: 15px;
font-family: "Overpass";
font-size: 14px;
font-weight: 400;
color: white
}

.vehicle-selector-inputs-wrap{
display: flex;
flex-direction: column;
flex-wrap: nowrap;
justify-content: flex-start;
align-items: flex-start;
}
.vehicle-selector-inputs-top3-wrap{
display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
align-items: flex-start;
}
.vehicle-selector-inputs-bottom3-wrap{
margin-top: 10px;
display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
align-items: flex-start;
}



.vehicle-selector-input-label{
font-family: "Overpass";
font-size: 14px;
font-weight: 100;
color: white
}
.noSelect {
-webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
-webkit-tap-highlight-color: transparent;
}

/* IE11 hide native button */
select::-ms-expand {
display: none;
}

.selectdiv select {
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
display: block;
width: 100%;
max-width: 320px;
height: 35px;
float: right;
margin: 5px 0px;
padding: 0px 10px;
font-size: 12px;
line-height: 1.75;
color: black;
background-color: #ffffff;
background-image: none;
border: 1px solid #cccccc;
-ms-word-break: normal;
word-break: normal;
}

.years-wrap{
width: 320px;
margin-right: 20px;
font-family: "Overpass";
font-size: 14px;
font-weight: 100;
cursor: pointer;
outline: none;
display: flex;
flex-direction: column;
justify-content: flex-start;
}

.makes-wrap{
width: 320px;
margin-right: 20px;
font-family: "Overpass";
font-size: 14px;
font-weight: 100;
cursor: pointer;
outline: none;
display: flex;
flex-direction: column;
justify-content: flex-start;
}
.makes-wrap-hide{
display: none;
}

.models-wrap{
width: 320px;
font-family: "Overpass";
font-size: 14px;
font-weight: 100;
cursor: pointer;
outline: none;
display: flex;
flex-direction: column;
justify-content: flex-start;
}
.models-wrap-hide{
display: none;
}

.versions-wrap{
width: 320px;
margin-right: 20px;
font-family: "Overpass";
font-size: 14px;
font-weight: 100;
cursor: pointer;
outline: none;
display: flex;
flex-direction: column;
justify-content: flex-start;
}
.versions-wrap-hide{
display: none;
}

.engtransm-wrap{
width: 320px;
margin-right: 20px;
font-family: "Overpass";
font-size: 14px;
font-weight: 100;
cursor: pointer;
outline: none;
display: flex;
flex-direction: column;
justify-content: flex-start;
}
.engtransm-wrap-hide{
display: none;
}

.buttonConsultar-wrap{

}
.buttonConsultar-wrap-hide{
display: none;
}
.buttonConsultar{
cursor: pointer;
outline: none;
margin-top: 23px;
width: 320px;
font-family: "Overpass";
font-size: 14px;
font-weight: 400;
color: white;
background-color: #0072ce;
border: 1px solid #0072ce;
height: 35px;
}
.buttonConsultar:hover{
/* background-color: #363636; */
/* border: 2px solid #363636; */
font-size: 16px;
font-weight: 700;
}


.selected-vehicle{
margin-left: 20px;
margin-top: 50px;
font-family: "Overpass";
font-size: 18px;
color: blue
}





@media screen and (max-width: 600px) {

    .vehicle-selector-title{
        margin-left: 20px;
        }
        .vehicle-selector-text{
        margin-left: 20px;
        }

    .vehicle-selector-bkgd{
        height: auto;
        padding-bottom: 20px;
        }

    .vehicle-selector-inputs-wrap{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        }

    .vehicle-selector-inputs-top3-wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        }
        .vehicle-selector-inputs-bottom3-wrap{
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        }

        .years-wrap{
            margin-right: 0;
        }
        .makes-wrap{
            margin-right: 0;
        }
        .versions-wrap{
            margin-right: 0;
        }
        .engtransm-wrap{
            margin-right: 0;
        }
}