a {
text-decoration: none;
color: black
}


.make-selector-bkgd{
width: 100%;
background-color: #cccccc;
height: 160px;
}


.make-selector-main-wrap{
margin: 0 auto;
max-width: 1024px;
}

.make-selector-title{
padding-top: 20px;
padding-bottom: 10px;
font-family: "Overpass";
font-size: 20px;
font-weight: 700;
color: #0072ce

}
.make-selector-text{
padding-bottom: 15px;
font-family: "Overpass";
font-size: 14px;
font-weight: 400;
color: black
}



.make-selector-inputs-top-wrap{
display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
align-items: flex-start;
}

.makes2-wrap{
width: 320px;
margin-right: 20px;
font-family: "Overpass";
font-size: 14px;
font-weight: 100;
cursor: pointer;
outline: none;
display: flex;
flex-direction: column;
justify-content: flex-start;
}


.makes2-selector-input-label{
font-family: "Overpass";
font-size: 14px;
font-weight: 100;
color: black
}


.makes2-selectdiv select {
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
display: block;
width: 100%;
max-width: 320px;
height: 35px;
float: right;
margin: 5px 0px;
padding: 0px 10px;
font-size: 12px;
line-height: 1.75;
color: black;
background-color: #ffffff;
background-image: none;
border: 1px solid #cccccc;
-ms-word-break: normal;
word-break: normal;
}

.makes2-buttonConsultar-wrap{

}
.makes2-buttonConsultar-wrap-hide{
display: none;
}
.makes2-buttonConsultar{
cursor: pointer;
outline: none;
margin-top: 5px;
width: 320px;
font-family: "Overpass";
font-size: 14px;
font-weight: 400;
color: white;
background-color: #0072ce;
border: 1px solid #0072ce;
height: 35px;
}











@media screen and (max-width: 600px) {

.make-selector-title{
margin-left: 20px;
}
.make-selector-text{
margin-left: 20px;
width: 90%;
}   

.make-selector-bkgd{
height: auto;
padding-bottom: 20px;
}


.make-selector-inputs-top-wrap{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.makes2-wrap{
    margin-right: 0;
}

}