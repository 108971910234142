
.result-vehicle-cards-wrap {
margin: 0 auto;
width: 100%;
min-height: 700px;
display: flex;
flex-direction: row;
justify-content: flex-start;
flex-wrap: wrap;
padding-top: 35px;
}




@media screen and (max-width: 600px) {


    .result-vehicle-cards-wrap{
        padding-top: 10px;
    }

    

}