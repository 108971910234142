html, body, ul, li, p, h1, h3, h4, h5 {
padding: 0;
margin: 0;
font-family: inherit;
list-style: none;
text-decoration: none;
}


.footer-bkgd {
  background-color: #363636;
  width: 100%;
  min-height: 60px;
  z-index: 1;
  }

.footer-wrap {
  font-family: "Overpass";
  color: white;
  margin: 0 auto;
  min-height: 55px;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  }

  .footer-text-wrap{
    margin-top: 20px;
    letter-spacing: .03125rem;
  }

  .footer-text1{
    font-weight: 400;
    font-size: 14px;
  }
  .footer-text2{
    padding-top: 20px;
    font-weight: 300;
    font-size: 14px;
  }
  .footer-text3{
    padding-top: 20px;
    font-weight: 300;
    font-size: 14px;
  }
  .footer-text4{
    padding-top: 5px;
    font-weight: 300;
    text-decoration: underline;
    cursor: pointer;
    outline: none;
    font-size: 14px;
  }
  .footer-text5{
    padding-top: 5px;
    font-size: 8px;
    font-weight: 100;
  }

  .footer-logo-pc{
    margin-left: 0px;
    margin-bottom: 20px;
    cursor: pointer;
    outline: none;
  }





@media screen and (max-width: 600px) {


  .footer-wrap {
    margin-left: 20px;
    align-items: flex-start;
    justify-content: space-between;
  }

  .footer-text-wrap{
    width: 80%;
    margin-bottom: 20px;
    margin-top: 20px;
    letter-spacing: .03125rem;
  }
  .footer-text4{
    font-size: 12px;
  }
  .footer-text5{
    padding-top: 10px;
  }
}