.make-usage-principal{
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
font-family: "Overpass";
color: black;
font-size: 11px;
font-weight: 300;
}

.make-usage-principal-hide{
display: none;
}

.result-make-usages-wrap{
width: 100%;
display: flex;
flex-direction: row;
justify-content: flex-start;
font-family: "Overpass";
color: black;
font-size: 12px;
font-weight: 300;
}

.make-usage-text-category{
width: 110px;
}
.make-usage-text-fyly{
width: 70px;
text-align: center;
}
.make-usage-text-pn{
width: 90px;
text-align: center;
}
.make-usage-text-name-wrap{
width: 300px;
display: flex;
flex-direction: row;
}
.make-usage-text-name{

}
.make-usage-text-usage_note{
font-weight: 100;
}










@media screen and (max-width: 600px) {


.result-make-usages-wrap{
flex-direction: column;
margin-bottom: 15px;
}

.make-usage-text-category{
width: 100%;
}
.make-usage-text-fyly{
width: 100%;
text-align: left;
}
.make-usage-text-pn{
width: 100%;
text-align: left;
font-weight: 700;
color: #0072ce;
}
.make-usage-text-usage_note{
font-weight: 300;
}

}