

.result-make-header-wrap{
width: 100%;
display: flex;
flex-direction: row;
justify-content: flex-start;
margin-top: 0px;
margin-bottom:7px;
font-family: "Overpass";
font-weight: 100;
font-size: 12px;
color: gray;
/* border-top: 1px solid rgb(218, 218, 218); */
border-bottom: 1px solid rgb(218, 218, 218);
/* padding-top: 2px; */
padding-bottom: 2px;
}

.result-make-header-wrap-hide{
display: none;
}
.result-make-header-category{
width: 110px;
}
.result-make-header-years{
width: 70px;
text-align: center;
}
.result-make-header-pn{
width: 90px;
text-align: center;
}
.result-make-header-partname{

}




@media screen and (max-width: 600px) {

.result-make-header-wrap{
display: none;
}
}