
.modalusages-show {
    display: block;
    }
    
    .modalusages-hide {
    display: none;
    }
    
    .modalusages-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.65);
    }
    
    .modalusages-main {
    z-index: 1010;
    position:fixed;
    background: white;
    width: 900px;
    max-width: 100%;
    top: 75px;
    left: 50%;
    transform: translate(-50%,0%);
    }
    .modalusages-main-wrap {
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
    }
    
    .modalusages-header-wrap{
    margin: 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    }
    .modalusages-header-part-wrap{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    }
    .modalusages-header-part-number-label{
    font-family: "Overpass";
    font-weight: 300;
    font-size: 11px;
    color: #8f8f8f;
    }
    .modalusages-header-part-number{
    font-family: "Overpass";
    font-weight: 400;
    font-size: 16px;
    color: #0072ce;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
    }
    
    .modalusages-close-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    outline: none;
    margin-bottom: 5px;
    }
    .modalusages-close-label{
    font-family: "Overpass";
    font-weight: 300;
    font-size: 12px;
    }

    .modalusages-table-wrap{
        margin: 0px 10px 10px 10px;
        max-height: 500px;
    }