a {
text-decoration: none;
color: black
}


.category-selector-bkgd{
width: 100%;
/* background-color: #f0f0f0; */
background-color: white;
height: 680px;
}


.category-selector-main-wrap{
margin: 0 auto;
max-width: 1024px;
}

.category-selector-title{
padding-top: 20px;
padding-bottom: 10px;
font-family: "Overpass";
font-size: 20px;
font-weight: 700;
color: #0072ce

}
.category-selector-text{
padding-bottom: 15px;
font-family: "Overpass";
font-size: 14px;
font-weight: 400;
color: black
}

.category-selector-cards-wrap{
width: 100%;
font-family: "Overpass";
font-size: 14px;
font-weight: 400;
color: black;
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
/* align-items: center; */
}

.category-selector-card{
margin-top: 10px;
display: flex;
flex-direction: column;
justify-content: flex-start;
cursor: pointer;
outline: none;

}
.category-selector-card-img{
width: 155px;
height: 155px;
background-color: white;
border: 1px solid #cccccc
}
.category-selector-card-label{
width: 100%;
margin-top: 5px;
text-align: center;
color: black
}








@media screen and (max-width: 600px) {


.category-selector-title{
margin-left: 20px;
}
.category-selector-text{
margin-left: 20px;
}
.category-selector-bkgd{
height: auto;
padding-bottom: 20px;
}

.category-selector-cards-wrap{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    }


}